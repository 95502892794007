import React from "react";
import teldiologo from "../assets/logo.png";
import Typography from "@mui/material/Typography";
import "./NotFound.css";
import { Button, Card, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderRadius: "15px",
  height: "40px",
  fontSize: "20px",
  width: "250px",
  marginTop: "20px",
  backgroundColor: "#A0AD39",
  "&:hover": {
    backgroundColor: "#788228",
  },
}));

function navigateSupport() {
  window.location.href = "http://support.teldio.com";
}

const Submitted = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "100px",
      }}
    >
      <Card sx={{ width: "600px", paddingBottom: "40px" }}>
        <Paper
          elevation={0}
          sx={{
            width: "600px",
            height: "15px",
            borderRadius: "2px 2px 0 0",
            backgroundColor: "#a0ad39",
          }}
        ></Paper>
        <img alt="Teldio logo" src={teldiologo} style={{ width: "300px", marginTop: "40px" }} />
        <h1 className="errorText" variant="h3">
          Form Submitted
        </h1>
        <ColorButton
          variant="contained"
          onClick={() => {
            navigateSupport();
          }}
        >
          Contact Support
        </ColorButton>
      </Card>
    </div>
  );
};

export default Submitted;
