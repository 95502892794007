import { useEffect, useState } from "react";
import "./App.css";
import NotFound from "./components/NotFound";
import { CircularProgress } from "@mui/material";
import Form from "./components/Form";

function App() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    // Get the part of the URL after the "/"
    const code = window.location.pathname.split("/").pop();

    // Make the GET request to the provided URL
    fetch(`/app/userform/get/${code}`)
      .then((response) => {
        // Check if the response status is 200 (OK)
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Not a 200 response");
        }
      })
      .then((data) => {
        setLoading(false);
        setData(data); // Set the data in the useState
      })
      .catch((error) => {
        setLoading(false);
        console.error("There was an error fetching the data", error);
      });
  }, []); // The empty array makes sure this useEffect runs only once after the component mounts

  return (
    <div className="App">
      <div>
        {loading ? (
          <div style={{marginTop: 100}}><CircularProgress /></div>
        ) : (
          <>
            {data ? <Form data={data} /> : <NotFound />}
          </>
        )}
      </div>
    </div>
  );
}

export default App;
