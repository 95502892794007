import React, { useEffect, useState } from "react";
import teldiologo from "../assets/logo.png";
import Typography from "@mui/material/Typography";
import { debounce } from "lodash";
import { Fade, CircularProgress } from "@mui/material";
import html2pdf from "html2pdf.js";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Paper,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Submitted from "./Submitted";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderRadius: "5px",
  height: "40px",
  fontSize: "20px",
  width: "250px",
  marginTop: "20px",
  marginBottom: "30px",
  backgroundColor: "#A0AD39",
  "&:hover": {
    backgroundColor: "#788228",
  },
}));

const Form = (props) => {
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);

  const saveAsPDF = () => {
    const content = document.body; // This captures the entire body. Modify as needed.

    const opt = {
      margin: 10,
      filename: "teldioForm.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    setLoading(false);

    html2pdf().from(content).set(opt).save();
  };

  // Get previous values if they exist
  const previousValues = JSON.parse(props.data[0].result) || {};

  // Initialize state based on the given JSON data
  const initialFormData = JSON.parse(props.data[0]?.jsonData || "[]").reduce(
    (acc, item) => {
      if (item.type === "textfield") {
        acc[item.id] = previousValues[item.id] || ""; // Fill with previous value or default to empty
      }
      if (item.type === "checkbox") {
        acc[item.id] = previousValues.hasOwnProperty(item.id)
          ? previousValues[item.id]
          : item.defaultChecked || false; // default to false if not set
      }
      return acc;
    },
    {}
  );

  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const saveProgress = () => {
    if (props.data[0].completed === true) {
      return;
    }

    setSaved(false);
    // Extract the last segment of the current URL
    const urlSegment = window.location.pathname.split("/").pop();

    // Construct the endpoint URL
    const endpoint = `/app/form/saveFormProgress/${urlSegment}`;

    // Use Fetch API to send the data
    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        setSaved(true);
        setTimeout(() => {
          setSaved(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const debouncedSave = debounce(saveProgress, 3000);

  useEffect(() => {
    debouncedSave();

    // Cleanup on component unmount
    return () => {
      debouncedSave.cancel();
    };
  }, [formData]);

  const handleChange = (value, id) => {
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  function sendData() {
    // Extract the last segment of the current URL
    const urlSegment = window.location.pathname.split("/").pop();

    // Construct the endpoint URL
    const endpoint = `/app/form/submitForm/${urlSegment}`;

    // Use Fetch API to send the data
    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        setSubmitted(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function parseContent(content) {
    const patterns = [
      {
        regex: /\*\*(.+?)\*\*/,
        createElement: (match, p1) => <b>{parseContent(p1)}</b>,
      },
      {
        regex: /__(.+?)__/,
        createElement: (match, p1) => <u>{parseContent(p1)}</u>,
      },
      {
        regex: /\[([^\[]+?)\]\((https?:\/\/[^\s\)]+)\)/,
        createElement: (match, p1, p2) => {
          return (
            <a href={p2} target="_blank" rel="noopener noreferrer">
              {parseContent(p1)}
            </a>
          );
        },
      },
    ];

    let reactElements = [];
    let currentIndex = 0;

    while (currentIndex < content.length) {
      let closestMatch = null;

      patterns.forEach((pattern) => {
        const match = pattern.regex.exec(content.substring(currentIndex));
        if (match && (!closestMatch || match.index < closestMatch.index)) {
          closestMatch = { match, pattern };
        }
      });

      if (!closestMatch) {
        reactElements.push(content.substring(currentIndex));
        break;
      }

      if (closestMatch.match.index > 0) {
        reactElements.push(
          content.substring(
            currentIndex,
            currentIndex + closestMatch.match.index
          )
        );
      }

      reactElements.push(
        closestMatch.pattern.createElement(...closestMatch.match)
      );
      currentIndex += closestMatch.match.index + closestMatch.match[0].length;
    }

    return reactElements;
  }

  return (
    <>
      <div
        style={{
          height: "30px",
          width: "100%",
          display: "flex",
          justifyContent: "right",
          position: "fixed",
        }}
      >
        <Fade in={saved} timeout={500}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "green",
              marginRight: "35px",
            }}
          >
            <CheckCircleIcon style={{ marginRight: "10px" }} />
            <span>Saved!</span>
          </div>
        </Fade>
      </div>
      {!submitted && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <form
            onSubmit={() => {
              sendData();
            }}
            style={{ width: "clamp(100px, 98%, 800px)" }}
          >
            <Card >
              <Paper
                elevation={0}
                sx={{
                  width: "100%",
                  height: "15px",
                  borderRadius: "2px 2px 0 0",
                  backgroundColor: "#a0ad39",
                }}
              />

              <img
                alt="Teldio logo"
                src={teldiologo}
                style={{ width: "200px", margin: "15px" }}
              />
              {props.data[0]?.jsonData?.length > 0 &&
                JSON.parse(props.data[0]?.jsonData).map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      margin: "20px",
                      justifyContent: "left",
                      textAlign: "left",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      {item.type === "textfield" && (
                        <TextField
                          variant="outlined"
                          fullWidth
                          disabled={props.data[0].completed}
                          onChange={(e) =>
                            handleChange(e.target.value, item.id)
                          }
                          label={item.content}
                          value={formData[item.id]}
                          required={item.required}
                        />
                      )}
                      {item.type === "checkbox" && (
                        <FormControlLabel
                          label={parseContent(item.content)}
                          sx={{ marginLeft: "5px" }}
                          control={
                            <Checkbox
                              checked={formData[item.id]}
                              disabled={props.data[0].completed}
                              onChange={(e) =>
                                handleChange(e.target.checked, item.id)
                              }
                            />
                          }
                        />
                      )}
                      {item.type === "label" && (
                        <Typography variant={item.labelFormat}>
                          {parseContent(item.content)}
                        </Typography>
                      )}
                      {item.type === "divider" && (
                        <div style={{ width: "560px" }}>
                          <Divider />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <Button
                  endIcon={<SaveAltIcon />}
                  disabled={loading}
                  sx={{ margin: "5px" }}
                  onClick={() => {
                    setLoading(true);
                    setTimeout(() => {
                      saveAsPDF();
                    }, 1000);
                  }}
                >
                  Save as PDF
                </Button>
              </div>
            </Card>
            <ColorButton
              variant="contained"
              type="submit"
              disabled={props.data[0].completed}
            >
              {props.data[0].completed === false
                ? "Submit Form"
                : "Form Submitted"}
            </ColorButton>
          </form>
        </div>
      )}
      {submitted && <Submitted />}
    </>
  );
};

export default Form;
